// import React, { Component, useEffect, useRef } from 'react'
import React, { useEffect, useRef } from 'react'

import "../css/normalize.css"
import "../css/reset.css"
import "../css/custom.css"
import "../css/fonts.css"
import Typing from 'react-typing-animation'
import Application from "../components/Application"
import Header from "../components/header"
import Footer from "../components/footer"
import GallerySlide from "../components/gallerySlide"

const textShowSpeed = 8;
// const textDeleteSpeed = 1;
// const textInitTime = 1500;
// const textPageTime = 2000;



// let textVisible = false;
// let currentSection = 0;
// let sectionVisible0, sectionVisible1, sectionVisible2, sectionVisible3, sectionVisible4, sectionVisibleLast, sectionVisibleContact;
// let linkto;

export default function Projects() {

  const hiddenRef0 = useRef();
  const hiddenRef1 = useRef();
  const hiddenRef2 = useRef();
  const hiddenRef3 = useRef();
  const hiddenRef4 = useRef();
  


  const AnimatedTypingComponent = () => (
    <div id="menugroup" className="in">
      <h3 id="t0" className="line1 show">
        {/* <Typing speed={textShowSpeed} className={"classname"} hideCursor={true} onStartedTyping={AddClasstoBody}></Typing> */}
      </h3>
      <nav id="menu">

        <span title="" id="t1" className="line2 title1 show">
          <Typing speed={textShowSpeed} className={"classname"} hideCursor={true} onStartedTyping={RollTop}>
            develop<span className="dt"></span>
          </Typing>
        </span>

        <span title="" id="t2" className="line2 title2 show">
          <Typing speed={textShowSpeed} className={"classname"} hideCursor={true}>
            <Typing.Delay ms={250} />
            design<span className="dt"></span>
          </Typing>
        </span>

        <span title="" id="t3" className="line2 title3 show">
          <Typing speed={textShowSpeed * 2} className={"classname"} hideCursor={true}>
            <Typing.Delay ms={500} />
          art<span className="dt"></span>
          </Typing>
        </span>

        <span title="" id="t4" className="line2 title4 show">
          <Typing speed={textShowSpeed} className={"classname"} hideCursor={true} onAfterType={RemoveClasstoBody}>
            <Typing.Delay ms={750} />
          produce<span className="dt"></span>
          </Typing>
        </span>

      </nav>
    </div>

  );

  const RollTop = () =>{
    window.scrollTo(0, 0);
  }
  const AddClasstoBody = () => {
    // console.log("hidden add");
    window.scrollTo(0, 0);
    document.body.classList.add("hidden");
  }

  const RemoveClasstoBody = () => {
    // console.log("hidden remove");
    document.body.classList.remove("hidden");
    const ullink = document.querySelectorAll("ul.gallerywrap");
    ullink[0].classList.add("galleryIn");
  }

  let textVisible = false;
  let currentSection = 0;
  let sectionVisible0, sectionVisible1, sectionVisible2, sectionVisible3, sectionVisible4, sectionVisibleLast, sectionVisibleContact;
  let linkto;


  useEffect(() => {
     

    window.addEventListener('scroll', scrollHandler);
    document.getElementsByClassName("line1")[0].addEventListener('animationend', animationHandler);
    document.getElementById("t1").addEventListener('animationend', animationHandler2);
    document.getElementById("t2").addEventListener('animationend', animationHandler2);
    document.getElementById("t3").addEventListener('animationend', animationHandler2);
    document.getElementById("t4").addEventListener('animationend', animationHandler2);
    const alink = document.querySelectorAll("ul.gallerywrap a.dlink");
    for (let i = 0; i < alink.length; i++) {
      alink[i].addEventListener("click", function () { delayClick(alink[i].rel) });
    }

    return () => {
      window.removeEventListener('scroll', scrollHandler);
      document.getElementsByClassName("line1")[0].removeEventListener('animationend', animationHandler);
      document.getElementById("t1").removeEventListener('animationend', animationHandler2);
      document.getElementById("t2").removeEventListener('animationend', animationHandler2);
      document.getElementById("t3").removeEventListener('animationend', animationHandler2);
      document.getElementById("t4").removeEventListener('animationend', animationHandler2);
      // document.querySelectorAll("a.gallerylink").removeEventListener('click', delayClick);
    };
  }, []);


  const delayClick = (msg) => {
    linkto = msg
    // console.log("linkto : "+linkto)
    //add class

    var line1 = document.getElementsByClassName("line1")[0];
    var title1 = document.getElementsByClassName("title1")[0];
    var title2 = document.getElementsByClassName("title2")[0];
    var title3 = document.getElementsByClassName("title3")[0];
    var title4 = document.getElementsByClassName("title4")[0];
    const galleryUL = document.querySelectorAll("ul.gallerywrap");
    for (let i = 0; i < galleryUL.length; i++) {
      galleryUL[i].classList.remove("galleryIn");
      galleryUL[i].classList.add("galleryOut");
    }

    line1.classList.add("hide");
    line1.classList.remove("show");

    title1.classList.add("hide");
    title1.classList.remove("show");

    title2.classList.add("hide");
    title2.classList.remove("show");

    title3.classList.add("hide");
    title3.classList.remove("show");

    title4.classList.add("hide");
    title4.classList.remove("show");

    setTimeout(GoLink, 1000);
  }
  function GoLink() {
    // window.location.href = linkto;
    window.scrollTo(0, 0);
    window.location.reload();
  }

  const animationHandler = () => {
    const cNmae = "dHide";
    textVisible = !textVisible;
    // console.log("textVisible : " + textVisible);
    if (!textVisible && currentSection != 0) {
      // document.querySelector("#t2").classList.add(cNmae);
      // document.querySelector("#t3").classList.add(cNmae);
      // document.querySelector("#t4").classList.add(cNmae);
    }
  }

  const animationHandler2 = () => {


    const cNmae = "dHide";
    switch (currentSection) {
      case 1:
        // document.querySelector("#t1").classList.add(cNmae);
        document.querySelector("#t2").classList.add(cNmae);
        document.querySelector("#t3").classList.add(cNmae);
        document.querySelector("#t4").classList.add(cNmae);
        break;
      case 2:
        document.querySelector("#t1").classList.add(cNmae);
        // document.querySelector("#t2").classList.add(cNmae);
        document.querySelector("#t3").classList.add(cNmae);
        document.querySelector("#t4").classList.add(cNmae);
        break;
      case 3:
        document.querySelector("#t1").classList.add(cNmae);
        document.querySelector("#t2").classList.add(cNmae);
        // document.querySelector("#t3").classList.add(cNmae);
        document.querySelector("#t4").classList.add(cNmae);
        break;
      case 4:
        document.querySelector("#t1").classList.add(cNmae);
        document.querySelector("#t2").classList.add(cNmae);
        document.querySelector("#t3").classList.add(cNmae);
        // document.querySelector("#t4").classList.add(cNmae);
        break;
      case 5:
        document.querySelector("#menugroup").classList.add(cNmae);
        document.querySelector("#t1").classList.add(cNmae);
        document.querySelector("#t2").classList.add(cNmae);
        document.querySelector("#t3").classList.add(cNmae);
        document.querySelector("#t4").classList.add(cNmae);
        break;
    }
  }

  const scrollHandler = () => {

    // const marginY = -500;
    const marginY = -(window.innerHeight * 0.25);
    sectionVisible0 = (window.pageYOffset + window.innerHeight + marginY >= hiddenRef0.current.offsetTop);
    sectionVisible1 = (window.pageYOffset + window.innerHeight + marginY >= hiddenRef1.current.offsetTop);
    sectionVisible2 = (window.pageYOffset + window.innerHeight + marginY >= hiddenRef2.current.offsetTop);
    sectionVisible3 = (window.pageYOffset + window.innerHeight + marginY >= hiddenRef3.current.offsetTop);
    sectionVisible4 = (window.pageYOffset + window.innerHeight + marginY >= hiddenRef4.current.offsetTop);
    // sectionVisibleLast = (window.pageYOffset + window.innerHeight + marginY >= hiddenRefLast.current.offsetTop);
    // sectionVisibleContact = (window.pageYOffset + window.innerHeight + marginY >= hiddenRefContact.current.offsetTop);
    // sectionVisibleLast = (window.pageYOffset + window.innerHeight + -50 >= hiddenRefLast.current.offsetTop);
    // sectionVisibleContact = (window.pageYOffset + window.innerHeight + -50 >= hiddenRefContact.current.offsetTop);


    if (sectionVisible0)
      currentSection = 0;
    if (sectionVisible1)
      currentSection = 1;
    if (sectionVisible2)
      currentSection = 2;
    if (sectionVisible3)
      currentSection = 3;
    if (sectionVisible4)
      currentSection = 4;
    if (sectionVisibleLast)
      currentSection = 5;
    if (sectionVisibleContact)
      currentSection = 6;

    const cNmae = "dHide";

    var menugroup = document.getElementById("menugroup");
    var line1 = document.getElementsByClassName("line1")[0];
    var title1 = document.getElementsByClassName("title1")[0];
    var title2 = document.getElementsByClassName("title2")[0];
    var title3 = document.getElementsByClassName("title3")[0];
    var title4 = document.getElementsByClassName("title4")[0];
    var wcover = document.getElementsByClassName("wcover")[0];
    if (currentSection == 0) {

      textVisible = true;
      document.querySelector("#menugroup").classList.remove(cNmae);
      document.querySelector("#t1").classList.remove(cNmae);
      document.querySelector("#t2").classList.remove(cNmae);
      document.querySelector("#t3").classList.remove(cNmae);
      document.querySelector("#t4").classList.remove(cNmae);

      menugroup.classList.add("in");
      menugroup.classList.remove("out");

      line1.classList.add("show");
      line1.classList.remove("hide");

      title1.classList.add("show");
      title1.classList.remove("hide");

      title2.classList.add("show");
      title2.classList.remove("hide");

      title3.classList.add("show");
      title3.classList.remove("hide");

      title4.classList.add("show");
      title4.classList.remove("hide");

      if (wcover.classList.contains("whide"))
        wcover.classList.remove("whide")


    } else {


      menugroup.classList.add("out");
      menugroup.classList.remove("in");

      line1.classList.add("hide");
      line1.classList.remove("show");

      const ullink = document.querySelectorAll("ul.gallerywrap");
      const gIn = "galleryIn";

      switch (currentSection) {
        case 1:
          //show
          title1.classList.add("show");
          title1.classList.remove("hide");

          //hide
          title2.classList.add("hide");
          title2.classList.remove("show");

          title3.classList.add("hide");
          title3.classList.remove("show");

          title4.classList.add("hide");
          title4.classList.remove("show");

          document.querySelector("#t1").classList.remove(cNmae);
          // document.querySelector("#t2").classList.remove(cNmae);
          // document.querySelector("#t3").classList.remove(cNmae);
          // document.querySelector("#t4").classList.remove(cNmae);

          if (wcover.classList.contains("whide"))
            wcover.classList.remove("whide")


          // if (ullink[1].classList.contains(gIn))
          //   ullink[1].classList.remove(gIn);
          // if (ullink[2].classList.contains(gIn))
          //   ullink[2].classList.remove(gIn);
          // if (ullink[3].classList.contains(gIn))
          //   ullink[3].classList.remove(gIn);

          break;
        case 2:

          //show
          title2.classList.add("show");
          title2.classList.remove("hide");

          //hide
          title1.classList.add("hide");
          title1.classList.remove("show");

          title3.classList.add("hide");
          title3.classList.remove("show");

          title4.classList.add("hide");
          title4.classList.remove("show");

          // document.querySelector("#t1").classList.remove(cNmae);
          document.querySelector("#t2").classList.remove(cNmae);
          // document.querySelector("#t3").classList.remove(cNmae);
          // document.querySelector("#t4").classList.remove(cNmae);

          if (wcover.classList.contains("whide"))
            wcover.classList.remove("whide")


          ullink[1].classList.add(gIn);
          // if (ullink[2].classList.contains(gIn))
          //   ullink[2].classList.remove(gIn);
          // if (ullink[3].classList.contains(gIn))
          //   ullink[3].classList.remove(gIn);

          break;
        case 3:

          //show
          title3.classList.add("show");
          title3.classList.remove("hide");

          //hide
          title1.classList.add("hide");
          title1.classList.remove("show");

          title2.classList.add("hide");
          title2.classList.remove("show");

          title4.classList.add("hide");
          title4.classList.remove("show");

          // document.querySelector("#t1").classList.remove(cNmae);
          // document.querySelector("#t2").classList.remove(cNmae);
          document.querySelector("#t3").classList.remove(cNmae);
          // document.querySelector("#t4").classList.remove(cNmae);

          if (wcover.classList.contains("whide"))
            wcover.classList.remove("whide")


          ullink[2].classList.add(gIn);
          // if (ullink[1].classList.contains(gIn))
          //   ullink[1].classList.remove(gIn);
          // if (ullink[3].classList.contains(gIn))
          //   ullink[3].classList.remove(gIn);

          break;
        case 4:
          //show
          title4.classList.add("show");
          title4.classList.remove("hide");

          //hide
          title1.classList.add("hide");
          title1.classList.remove("show");

          title2.classList.add("hide");
          title2.classList.remove("show");

          title3.classList.add("hide");
          title3.classList.remove("show");

          // document.querySelector("#t1").classList.remove(cNmae);
          // document.querySelector("#t2").classList.remove(cNmae);
          // document.querySelector("#t3").classList.remove(cNmae);
          document.querySelector("#t4").classList.remove(cNmae);


          if (wcover.classList.contains("whide"))
            wcover.classList.remove("whide")


          ullink[3].classList.add(gIn);
          // if (ullink[1].classList.contains(gIn))
          //   ullink[1].classList.remove(gIn);
          // if (ullink[2].classList.contains(gIn))
          //   ullink[2].classList.remove(gIn);


          break;

        case 5:
          //hide
          title1.classList.add("hide");
          title1.classList.remove("show");

          title2.classList.add("hide");
          title2.classList.remove("show");

          title3.classList.add("hide");
          title3.classList.remove("show");

          title4.classList.add("hide");
          title4.classList.remove("show");

          wcover.classList.add("whide")


          break;
      }

    }

    // const pageNumobj = document.querySelector(".current");
    // pageNumobj.innerHTML = currentSection;


  }



  return (
    <div>
      <Header href="/" className="projects"></Header>
      <div id="content" className="clearfix">

        <div id="main" role="main" className="col three-quarters">
          <AnimatedTypingComponent></AnimatedTypingComponent>
        </div>
        <div className="sectionwrap">

          <div ref={hiddenRef0}>
            <section className="sectionspace"></section>
          </div>

          <div ref={hiddenRef1}>
            <GallerySlide pathRef={"listing/develop"} classRef=""></GallerySlide>
          </div>

          <div ref={hiddenRef2}>
            <GallerySlide pathRef={"listing/design"} classRef=""></GallerySlide>
          </div>

          <div ref={hiddenRef3}>
            <GallerySlide pathRef={"listing/art"} classRef=""></GallerySlide>
          </div>

          <div ref={hiddenRef4}>
            <GallerySlide pathRef={"listing/produce"} classRef=""></GallerySlide>
          </div>

        </div>

      </div>

      <Footer pageNow={"projects"} ></Footer>


      <div id="aframe"></div>

      <Application pageNow=""></Application>
    </div>
  )
}